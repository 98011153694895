import React from 'react';
import logo from '../assets/logo.svg';

const PrivacyPolicyYur = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <p><strong>OMMAVIY OFERTA</strong><br />
          «Road 24» platformasi hamkorlari uchun</p>

        <p>Ushbu oferta “Road24” platformasini ishlab chiquvchisi “KASH APP” MChJ (keyingi o’rinlarda Kontekstga qarab Ma’muriyat yoki Operator deb yuritiladi) tomonidan Road24 platformasini taqdim etish bo’yicha xizmatlar ko’rsatish yuzasidan rasmiy ommaviy ofertaga hisoblanadi. Ushbu Ofertada ko’rsatilgan harakatlarning bajarilishi hamkorlarning ushbu Ofertada (keyingi o’rinlarda Shartnoma deb yuritiladi) belgilangan shartlarda, tartibda va hajmda xizmatlar ko’rsatish shartnomasini tuzishga roziligini (qabul qilishini) so’zsiz tasdiqlanishini bildiradi.</p>

        <p>
          <strong>1. ASOSIY TUSHUNCHALAR</strong><br />
          1.1. Ushbu shartnomada quyidagi asosiy tushunchalardan foydalaniladi: Maʼmuriyat (sayt maʼmuriyati yoki operatori) «KASH APP» MChJ sifatida Road24 platformasini ishlab chiqaruvchisi hisoblanadi. Bank: AT “ANORBANK”, bank kodi: 01183, Hisob raqami: 20208000500602667001; OKED 62010, STIR: 303 858 131.</p>

        <p>«Road24» platformasi (veb-sayt (https://road24.uz va yoki ilova) hamkorlarga bir qator innovatsion va boshqa xizmatlarni taqdim etish va olish imkonini beruvchi (kompyuterlar uchun) onlayn platforma koʻrinishidagi dasturdir. Platforma rasmiy ravishda ro'yxatdan o'tgan mualliflik huquqi ob'ekti bo'lib, elektron kompyuter uchun dastur hisoblanadi. Platforma Hamkorlarning dasturiy interfeysini Platformaga integratsiyalashgan holda Hamkorlar tomonidan taqdim etilayotgan xizmatlar uchun mijozlarni topish sohasida tomonlar o‘rtasida o‘zaro manfaatli hamkorlikka xizmat qiladi.</p>

        <p>Hamkor – Platforma va uning funksiyalaridan foydalanishni boshlagan va Platformada oʻz akkauntiga ega boʻlgan yuridik shaxs;<br />
          Hamkor tashkilotlar - banklar, sug'urta tashkilotlari, reklama beruvchilar va xizmatlari «Road24» platformasi orqali taqdim etiladigan boshqa tashkilotlar;</p>

        <p>Xizmatlar (xizmat) – «Road24» platformasi orqali Hamkorlarga ko'rsatiladigan axborot va konsalting xizmatlari;<br />
          Xizmatlarni oluvchilar (mijozlar) hamkor tashkilotlar tomonidan ko'rsatiladigan xizmatlardan foydalanuvchi va haq to'laydigan jismoniy va yuridik shaxslardir.</p>

        <p>Ma'muriyat tizimiga ulanish - bu Platforma va Hamkorning harakatlaridan so’ng (API orqali ulanish), Platforma Hamkorga o'z xizmatlarini taqdim etishga imkoni paydo bo’lishi.<br />
          Buyurtma - To'lovni amalga oshirish uchun zarur bo'lgan ma'lumotlarni o'z ichiga olgan elektron hujjat ko'rinishidagi xizmatni oluvchining buyrug'i.</p>

        <p>
          To'lov - Kompaniya, Platforma, To'lovchi, Qabul qiluvchi, banklar va To’lov tizimlari o'rtasida elektron shaklda ma'lumot almashish yo'li bilan bank hisobvarag'idan pul mablag'larini yechib olish va Hamkorlar hisobvaraqlariga o'tkazish (bank kartasidan foydalangan holda pul mablag'larini o'tkazish) bo'yicha tizimda ketma-ket harakatlarni amalga oshirish hisoblanadi.<br />
          To‘lov summasini taqsimlash - To‘lovni amalga oshirish jarayoni bo‘lib, bunda Xizmatni oluvchining bank kartasidan pul mablag‘larining bir martalik debeti (to‘liq To‘lov summasi) amalga oshiriladi va mablag‘lar bir nechta Qabul qiluvchilar foydasiga qisman o‘tkaziladi.<br />
          1.2. Ushbu Shartnoma 1.1-bandda ko'rsatilmagan shartlardan ham foydalanishi mumkin. Bunday atamalarning ma'nosi fuqarolik va boshqa qonun hujjatlariga va Shartnomaning ushbu matniga muvofiq tushunilishi kerak.<br />
          <strong>2. QABUL QILISh TARTIBI</strong><br />
          2.1. Ushbu Ommaviy ofertani qabul qilish hamkor va Ma’muriyat o‘rtasida elektron aloqa vositalari (didox.uz, faktura.uz) orqali tarif rejasini tasdiqlash orqali amalga oshiriladi. Platforma IOS, Android platformalarida va www.road24.uz saytida ishlaydi. Shartnoma taklif qabul qilingan paytdan boshlab tuzilgan hisoblanadi.<br />
          2.2. Agar Hamkor ushbu Shartnoma shartlariga rozi bo'lmasa, u Saytdan (Platformadan) foydalanishni to'xtatishi kerak.<br />
          2.3. Agar Hamkorlar Hamkorlik shartnomasini buzsa, Sayt bir tomonlama tartibda ushbu Shartnomani to'liq yoki qisman bekor qilishi yoki cheklovlar qo'yishi mumkin.<br />
          2.4. Platforma tomonidan ko'rsatiladigan xizmatlarning to'xtatilishi Ma'muriyat va Hamkor o'rtasidagi shartnomaning bekor qilinishini anglatadi.<br />
          2.5. Taklif hududi O'zbekiston Respublikasi hisoblanadi.<br />
          3. SHARTNOMA PREDMETI<br />
          3.1. Ushbu Shartnomaning predmeti Hamkorlarning dasturiy interfeysini Platformaga integratsiyalashgan holda Hamkorlar tomonidan ko‘rsatiladigan xizmatlar uchun mijozlarni topish sohasida tomonlarning o‘zaro manfaatli hamkorligidir.<br />
          3.2. Hamkorlar va xizmatlarni oluvchilar o'rtasidagi huquqiy munosabatlar alohida shartnomalar bilan tartibga solinadi. Hamkorlar (hamkor tashkilotlar) o'rtasida ushbu shartnoma yoki platforma orqali taqdim etiladigan xizmatlarni taqdim etish bo'yicha nizo yuzaga kelgan taqdirda, Tomonlar ushbu nizoni mustaqil ravishda hal qilish uchun barcha choralarni ko'rishlari kerak. Ma'muriyat Hamkorlar va xizmatni oluvchilar o'rtasidagi huquqiy munosabatlar va nizolar uchun aralashmaydi, ishtirok etmaydi va javobgar emas. Platforma orqali ma'muriyat faqat ular orasidagi bog'lovchi bo'g'in vazifasini bajaradi.</p>

        <p>3.3. Ushbu Shartnomaga muvofiq, Hamkor Platformaning pullik xizmatlaridan foydalanadi, Foydalanish shartlariga rioya qiladi va belgilangan tartibda ko'rsatiladigan pullik xizmatlar uchun vositachilik haqini to'lash majburiyatini oladi. Ma'muriyat Platformaning uzluksiz ishlashini ta'minlaydi, Hamkorlarga Platformaning xizmatlari va imkoniyatlaridan pullik foydalanishni ta'minlaydi va belgilangan tartibda to'lovlarni undiradi.</p>

        <p>3.3.1 Shu bilan birga, Operator O'zbekiston Respublikasi qonun hujjatlarida (keyingi o'rinlarda Respublika deb yuritiladi) taqiqlanmagan Platformaning funksionalligida nazarda tutilgan xizmatlar va boshqa operatsiyalarni amalga oshirishda faqat texnik va axborot o'zaro hamkorligini ta'minlaydi. O'zbekiston) va Hamkor foydasiga karta/mijozning joriy hisobvaraqlaridan tegishli mablag'larni yechib olish va kreditlash uchun javobgar emas.</p>

        <p>3.4. Shartnomada bevosita nazarda tutilmagan barcha hollarda tomonlar O‘zbekiston Respublikasining amaldagi qonunchiligiga va tadbirkorlik odatlariga rioya qilishlari shart. Hamkor tomonidan ushbu Shartnomaning qabul qilinishi quyidagi hujjatlarni ham qabul qilinganligini anglatadi:</p>

        <p>3.4.1 - “Road24 Partners shaxsiy maʼlumotlari uchun maxfiylik siyosati” (https://road24.uz/privacy-policy/ manzilida joylashgan). Ushbu hujjat ushbu Bitimning ajralmas qismi hisoblanadi.</p>

        <p>3.5. Ushbu Shartnoma (shu jumladan uning qismi) Operator tomonidan istalgan vaqtda bir tomonlama ravishda o'zgartirilishi yoki bekor qilinishi mumkin. Agar hujjatlarning yangi tahrirlarida boshqacha tartib nazarda tutilgan bo‘lmasa, hujjatlarning yangi tahrirlari ular e’lon qilingan kundan boshlab kuchga kiradi. Ushbu Ofertaga o'zgartirish va qo'shimchalar kiritilgandan keyin Hamkorning Sayt xizmatlaridan foydalanishda davom etishi o'zgartirish va qo'shimchalar u tomonidan qabul qilinganligini anglatadi.<br />
          3.6. Platforma xizmatlaridan foydalanishdan oldin Hamkor ushbu taklifni toʻliq oʻqib chiqishi va tarif rejasida koʻrsatilgan narxlar bilan tanishishi kerak. Ushbu Ommaviy ofertani qabul qilish Ma’muriyatning tarif rejasini sherik va Ma’muriyat o‘rtasida elektron aloqa vositalari (didox.uz, faktura.uz) orqali tasdiqlash orqali amalga oshiriladi, hamda hamkorning O‘zbekiston Respublikasi Fuqarolik kodeksining 357, 364, 365, 366, 367, 369 va 370-moddalariga muvofiq Ofertaga to‘liq va so‘zsiz rioya etilishini bildiradi.</p>

        <p>
          3.7. Ushbu shartnoma ochiq va hamma uchun ommaviy hujjatdir.<br />
          3.8. Platforma tomonidan koʻrsatiladigan xizmatlar sugʻurta kompaniyalari, banklar va boshqa tashkilotlarga birgalikda taqdim etiladi. Ma'muriyat sheriklar yoki boshqa yuridik va jismoniy shaxslar o'rtasida ushbu shartnoma bilan bog'liq yuzaga keladigan muammolar uchun javobgar emas.<br />
          3.9. Ushbu Shartnomani tuzish orqali Hamkor O'zbekiston Respublikasining «SHaxsga doir ma’lumotlar to‘g‘risida»gi qonun hujjatlari talablariga, Operatorning Internet-resursida joylashtirilgan shaxsiy ma'lumotlarni himoya qilish va qayta ishlash siyosatiga muvofiq Operatorga uning shaxsiy ma'lumotlarini qayta ishlashga to'liq, cheksiz roziligini, ushbu shartnomada belgilangan tartibda va shartlarda beradi. <br />
          3.10. Ushbu taklif O‘zbekiston Respublikasining Fuqarolik kodeksi, O‘zbekiston Respublikasining “Elektron tijorat to‘g‘risida”, “Elektron hujjat aylanishi to‘g‘risida”gi qonunlari va boshqalar asosida ishlab chiqilgan.<br />
          <strong>4. PLATFORMA STATUSI</strong><br />
          4.1. Platforma – internet-resurs (axborot vositachisi, veb-sayt), https://road24.uz manzilida internet tarmog‘ida bunday ma’lumotlarni taqdim etuvchi kompyuter dasturlari va ma’lumotlar to‘plamini aks ettiradi va Sayt ilovasida ochiq joylashtirishni ta’minlaydi.</p>

        <p>4.2. https://road24.uz (domen nomi) tarmoq manzili va undan foydalanish bilan bog'liq barcha huquqlar Sayt ma'muriyatiga tegishli. Manfaatdor shaxslarning Saytga kirishi O‘zbekiston Respublikasining amaldagi qonunchiligi va ushbu shartnomaga muvofiq belgilanadi.<br />
          4.3. Saytning alohida bo'limlarida Hamkor va boshqa shaxslar tomonidan mustaqil ravishda joylashtirilgan ma'lumotlar va intellektual faoliyat natijalaridan foydalanish huquqi Ma’muriyatga tegishli.</p>

        <p>4.4. Jismoniy va yuridik shaxslarning ushbu Hamkorlik shartnomasi boʻyicha yoki Sayt faoliyati bilan bogʻliq ariza, taklif va shikoyatlari, shuningdek, vakolatli organlarning soʻrovlari Maʼmuriyatning support@road24.uz elektron pochta manziliga yoki elektron pochta manziliga yoki ko’rsatilgan telefon raqamiga yoki Hamkorlarni qoʻllab-quvvatlash xizmati orqali qabul qilinadi.<br />
          4.5. Ushbu shartnoma hech qanday tarzda Hamkorga kompaniya nomi, savdo belgisi, domen nomi va Sayt ma'muriyatining boshqa taniqli belgilaridan foydalanish huquqini bermaydi. Sayt ma'muriyatining kompaniya nomi, tovar belgisi, domen nomi va boshqa taniqli belgilaridan foydalanish huquqi Sayt ma'muriyatining yozma roziligi bilan berilishi mumkin.</p>

        <p>
          4.6. Saytni boshqarish va rivojlantirish O‘zbekiston Respublikasi qonunchiligiga muvofiq Ma’muriyat tomonidan amalga oshiriladi.<br />
          <strong>5. TOMONLARNING HUQUQ VA MAJBURATLARI</strong><br />
          5.1. Ma'muriyat va hamkor(lar) ushbu Shartnoma taraflari hisoblanadi.<br />
          5.2. Ushbu Bitim taraflari quyidagi huquqlarga ega:<br />
          5.2.1. Platforma ma'muriyati quyidagi huquqlarga ega:<br />
          - belgilangan tartibda Hamkorlarga xizmat ko‘rsatish, ulanish o‘rnatish, qabul qilish, ruxsat berish yoki ularni Platformada bekor qilish;<br />
          - pullik xizmatlar uchun to'lovlarni undirish.<br />
          - o'z mablag'laridan va o'z ixtiyoridan kelib chiqib, o'z ilovalari va Internet resurslari orqali Hamkorlar tomonidan taklif etilayotgan xizmatlarni ilgari surish maqsadida reklama kampaniyalarini o'tkazish.<br />
          - Potentsial Mijozlarni izlash va ularga Hamkorlar xizmatlarini taklif qilish, shuningdek ularni xizmatlar ko'rsatish shartlari bilan tanishtirish.</p>

        <p>5.2.2. Hamkor quyidagi huquqlarga ega:<br />
          - Belgilangan shartlarga muvofiq Platforma xizmatlarini qabul qilish;<br />
          - kelishilgan shartlar asosida platformaga kirish huquqiga ega bo'lish;<br />
          - Platforma orqali xizmatlar ko'rsatish;<br />
          - kerak bo'lganda ma'muriyatdan tushuntirishlar va yordam olish.<br />
          5.3. Ushbu Shartnoma taraflari quyidagi majburiyatlarni bajaradilar.<br />
          5.3.1. Platforma ma'muriyati (operatori) quyidagi majburiyatlarni bajaradi:<br />
          - Ushbu Shartnoma shartlariga muvofiq xizmatlar uchun to'lovni o'z vaqtida o’tkazish.<br />
          - mazkur Shartnomada nazarda tutilgan talab va standartlarga qat’iy rioya qilgan holda harakat qilish, shuningdek, xizmat ko‘rsatish sohasida O‘zbekiston Respublikasining amaldagi qonunchiligiga qat’iy rioya qilish.<br />
          - Axborot parametrlarini qabul qilish, tekshirish va jo‘natish bo‘yicha texnik talablarga, shuningdek, Platforma bilan ishlash qoidalariga qat’iy rioya qilgan holda harakat qilish.<br />
          - Maxfiylik siyosatiga muvofiq Hamkorlarning shaxsiy ma'lumotlarining maxfiyligini ta'minlash (ommaga ochiq ma'lumotlar bundan mustasno);<br />
          - «Road24» platformasining uzluksiz va maqsadli ishlashini ta'minlash va rivojlantirish;<br />
          - ushbu Hamkorlik shartnomasi shartlariga rioya qilish (ommaviy oferta).<br />
          - Platformada Hamkorlar tomonidan ko'rsatiladigan xizmatlar haqida ma'lumot joylashtirish.<br />
          - Hamkorlardan ushbu Shartnoma bo'yicha o'z majburiyatlarini to'g'ri bajarishlarini talab qilish.<br />
          5.3.2. Hamkor quyidagi majburiyatlarni bajaradi:<br />
          - ushbu Hamkorlik shartnomasi shartlariga rioya qilish (ommaviy oferta);<br />
          - to'g'ri va aniq ma'lumotlarni taqdim etish;<br />
          - Mijozlar tomonidan xizmatlarni sotib olish va to'lash fakti to'g'risida ma'muriyatni xabardor qilish.<br />
          - Sayt xizmatlaridan foydalanish jarayonida amaldagi qonunchilikka zid bo‘lgan va uchinchi shaxslarning qonuniy manfaatlariga zarar yetkazishi mumkin bo‘lgan, shuningdek, reklama, erotik, pornografik va haqoratomuz so‘zlardan (ma’lumotlardan) foydalanmaslik;<br />
          - uchinchi shaxslarning (har qanday) ma'lumotlarga (materiallarga), sharhlarga mualliflik huquqlarini, tovar belgilarini, boshqa intellektual mulk huquqlarini buzmaslik;<br />
          - ko'rsatilgan pullik xizmatlar uchun to'lovlarni o'z vaqtida to'lash;<br />
          - Saytdan noqonuniy maqsadlarda foydalanmaslik, uni o'zgartirmaslik;<br />
          - Boshqa odamlarning shaxsiy ma'lumotlari va maxfiyligining ta’minlash.<br />
          - Xizmatdan ushbu Shartnomada belgilangan tartibda foydalanish.<br />
          - Tegishli Xizmat interfeyslarida Operator tomonidan e'lon qilingan Hamkorlar uchun xabarlarni kuzatib borish. Ushbu xabarlardagi talablarga rioya qiling.<br />
          - Shaxsiy ma'lumotlarini va/yoki saytga kirishni ta'minlaydigan boshqa ma'lumotlar va ma'lumotlarni uchinchi shaxslarga o'tkazmaslik (keyingi o'rinlarda "Xizmatga kirish uchun ma'lumotlar" deb yuritiladi).<br />
          - Rekvizitlarni (login va parollarni) uchinchi shaxslar tomonidan noqonuniy egallanishi va foydalanilishining oldini olish uchun zarur tashkiliy va texnik choralarni ko'rish.<br />
          - Operatorni saytga kirish uchun hisob ma'lumotlaridan uchinchi shaxslar tomonidan noqonuniy foydalanish, shu jumladan Xizmat ulangan mobil telefon raqamiga kirish huquqini yo'qotish (yo'qotish, o'g'irlash yoki yo'qotishning boshqa usullari) bilan bog'liq faktlar yoki shubhalar haqida darhol xabardor qilish; <br />
          - Sayt orqali noqonuniy moliyaviy operatsiyalarni, noqonuniy savdoni, jinoyatdan olingan daromadlarni legallashtirishga yoki terrorizmni moliyalashtirishga qaratilgan operatsiyalarni yoki qonun hujjatlarida taqiqlangan boshqa harakatlarni amalga oshirmaslik.<br />
          - Tizimli ravishda foyda olishga yoki daromadni yashirishga qaratilgan operatsiyalarni amalga oshirish uchun Xizmatdan foydalanmaslik.<br />
          5.4. Hamkor Platformaga to'g'ri va to'g'ri ma'lumotlarni taqdim etishga majburdir. Hamkor tomonidan Sayt ma'muriyatiga ma'lumot taqdim etilmagan yoki to'liq taqdim etilmagan taqdirda, Sayt ma'muriyati Hamkorning ulanishini/ro'yxatdan o'tishini bekor qilishi yoki uni Hamkor xohlaganidan boshqa shaklda ro'yxatdan o'tkazishi mumkin.<br />
          5.5. Platformada boshqa Hamkorlarni chalg'itishga qaratilgan harakatlar, shuningdek, quyidagilar qat'iyan man etiladi:<br />
          - bir nechta akkauntlar yaratish / bir nechta API-larga qo'shilish, o'zini boshqa birovga o'xshatish, ayniqsa boshqa kompaniyalar, yuridik shaxslarning nomlari yoki taxalluslari bilan qayta-qayta obuna bo'lish;<br />
          - yolg'on ma'lumotlar asosida qo'shilish, ma'lumotni buzib ko'rsatish, o'zi haqida yolg'on ma'lumot joylashtirish va boshqalar;<br />
          - ariza blankalarida keraksiz, keraksiz ma'lumotlarni joylashtirish;<br />
          - xabar kimga qaratilganligini aniqlab bo'lmaydigan darajada ma'lumotni buzib ko'rsatadigan sharhlar qoldirish;<br />
          - sayt xizmatlaridan foydalanishda qo'pol imlo xatolariga yo'l qo'yish;<br />
          - xizmatlaridan shaxsan olmagan yoki foydalanmagan shaxslar haqida Platformada sharhlar va reytinglar qoldirish.<br />
          - xizmatlar ko‘rsatishda O‘zbekiston Respublikasining amaldagi qonunchiligini buzish.<br />
          5.6. Platformada joylashtirilgan barcha obyektlar, shu jumladan dizayn elementlari, matnlar, grafikalar, rasmlar, videolar, skriptlar, dasturlar va boshqa ob'ektlar va ularning komplekslari (keyingi o'rinlarda Kontent deb yuritiladi) Sayt ma'muriyati, sayt hamkorlari va boshqa mualliflik huquqi bilan himoyalangan. huquq egalari.<br />
          5.7. Ushbu Hamkorlik shartnomasida ko'rsatilganidan tashqari va amaldagi qonunchilikka muvofiq, agar muallifning aniq ruxsati bo‘lmasa, muallifning oldindan roziligisiz har qanday kontentni har qanday vositalar bilan nusxalash (qayta nashr etish), qayta ishlash, tarqatish, namoyish qilish, nashr qilish, yuklab olish, uzatish, sotish yoki to'liq yoki qisman sotib olish mumkin emas. <br />
          5.8. Hamkor boshqa saytlar, ma'lumotlar bazalari va boshqa mualliflarning mazmuni bilan bog'liq intellektual faoliyat natijalarini muallifning roziligisiz, Platformada yuklash yoki nashr etish huquqiga ega emas, uning shaxsiy akkaunti bundan mustasno,.<br />
          5.9. Hamkor amaldagi qonunchilikka muvofiq ro‘yxatdan o‘tish jarayonida taqdim etilgan, shuningdek uning shaxsiy kabinetiga joylashtirilgan shaxsiy ma’lumotlarni qayta ishlash va saqlash hamda uchinchi shaxslarga taqdim etishga rozilik bergan deb hisoblanadi. Shaxsiy ma’lumotlarni qayta ishlash O‘zbekiston Respublikasi qonun hujjatlariga muvofiq amalga oshiriladi.<br />
          5.10. Sayt ma'muriyati Hamkorning shaxsiy ma'lumotlarini qayta ishlash va saqlashi mumkin, shunda u Sayt xizmatlaridan foydalanishi va Sayt xizmatlari sifatini yaxshilashga qaratilgan tegishli ma'lumotlarni tahlil qilishi mumkin.<br />
          5.11. Sayt ma'muriyati Hamkorning shaxsi bilan bog'liq barcha ma'lumotlarni noqonuniy olish, o'zgartirish, oshkor qilish yoki yo'q qilishning oldini olish choralarini ko'radi. Sayt ma'muriyati boshqalarga hamkor ma'lumotlari bilan ishlashga faqat sayt funksiyalarini sozlash va texnik xizmat ko'rsatish uchun zarur bo'lganda ruxsat berishi mumkin.<br />
          5.12. Sayt ma'muriyati Hamkor tomonidan taqdim etilgan ma'lumotlardan, shuningdek, shaxsiy ma'lumotlardan O'zbekiston Respublikasi qonunchiligi talablariga rioya qilish (shu jumladan, Hamkorning qonunga xilof harakatlarining oldini olish yoki bostirish) maqsadida foydalanishi mumkin. Hamkorlar toʻgʻrisidagi maʼlumotlar Oʻzbekiston Respublikasi qonun hujjatlariga muvofiq, sudning kelishuvi yoki qonun hujjatlarida vakolat berilgan boshqa huquqni muhofaza qiluvchi va davlat organlarining iltimosiga binoan boshqa shaxslarga ham berilishi mumkin.<br />
          5.13. Saytdan foydalanishda Hamkor ushbu Qoidalarga rioya qilishiga shubha tug‘ilsa, Sayt ma’muriyati qonun hujjatlariga muvofiq oldindan ogohlantirmasdan istalgan vaqtda Hamkorning ro‘yxatdan o‘tish ma’lumotlarini o‘chirish, ulanishni taqiqlash/bekor qilish yoki o‘zgartirishga haqli.<br />
          5.14. Sayt xizmatlarni taqdim etishni ta'minlash uchun Platformada Hamkor tomonidan kiritilgan maxfiy bo'lmagan ma'lumotlardan foydalanishi mumkin.</p>

        <p></p>

        <p></p>

        <p></p>

        <p></p>

        <p></p>

        <p></p>

        <p>
          <strong>6. PULI XIZMATLARNING NARXI VA TO'LOV TARTIBI</strong><br />
          6.1. Platforma yuridik shaxslarga pullik xizmatlar ko‘rsatadi. Pullik xizmatlarning narxi va to'lov tartibi ushbu Shartnomada va/yoki Platformaning o'zida belgilangan qoidalar bilan tartibga solinadi.</p>

        <p>6.2. Xizmatlarning narxi Ma'muriyat tomonidan belgilangan tariflar bilan belgilanadi. Ma’muriyatning tarif rejalarini elektron aloqa vositalari (didox.uz, faktura.uz) orqali imzolash va tasdiqlash Hamkorning xizmat narxi va ushbu shartnoma shartlariga roziligini bildiradi.<br />
          6.3. Platforma har bir hisobot oyi natijalariga ko‘ra, xizmat ko‘rsatuvchilar tomonidan yoki boshqa vositalar orqali amalga oshirilgan To‘lovlar soni va hajmi to‘g‘risida hisobot tuzadi, buning asosida Ma’muriyat hisobot davri tugagan kundan boshlab 3 kalendar kun ichida hamkor tashkilotlarga hisob-faktura beradi.</p>

        <p>
          6.4. Tomonlar Ma'muriyat 6.3-bandga muvofiq hamkor tashkilotlar tomonidan to'lov amalga oshirilish uchun hisob-fakturani o'z vaqtida berishiga rozi bo'ladilar.<br />
          Hisob-faktura uchun to‘lov Hamkor tashkilotlar tomonidan 3 kalendar kun ichida Ma’muriyatning bank hisob raqamiga o‘tkazilishi kerak.</p>

        <p>6.5. Platforma sug'urta kompaniyalari, banklar va hamkor tashkilotlar o'rtasida vositachi vazifasini bajaradi. Platforma hamkor tashkilotlar, sug'urta kompaniyalari, banklar va boshqalar tomonidan belgilangan xizmatlar narxi uchun javobgar emas.</p>

        <p>6.6. Platforma vositachi sifatida Hamkor tashkilotlardan belgilangan tartibda haq oladi. Uzrli sabablarsiz toʻlovlar amalga oshirilmagan taqdirda, Maʼmuriyat Platformadagi hamkor tashkilotlar xizmatlarini cheklash yoki bekor qilish choralarini koʻradi.</p>

        <p>
          6.7. Platformadan foydalanishda va undan foydalanishda xizmatlarni oluvchilar va Hamkor o‘rtasida kelishmovchiliklar yuzaga kelgan taqdirda, Ma’muriyat muammoli vaziyatga aralashmaydi va hal qilmaydi.<br />
          6.8. Hamkor va Xizmatni oluvchi o'rtasidagi pul mablag'larini qaytarish bilan bog'liq savollar va nizolar ushbu tomonlar o'rtasidagi alohida kelishuv bilan hal qilinadi. Bunday holda, Ma'muriyat pul mablag'larini qaytarmaydi, qoplamaydi va qaytarilishi uchun javobgar emas. Xizmatlarni ko'rsatish uchun olingan to'lov qaytarilmaydi.</p>

        <p>6.9. Qarzdorlik yoki to’lov ushbu ofertanong 6.4-bandda nazarda tutilgan muddatlarda to'lanmagan taqdirda (agar mavjud bo'lsa), ushbu shartnoma bo'yicha ma'muriyat so'zsiz va da'vosiz tarzda Hamkorning bankiga to'lanadigan qarz miqdorida to'lov talabnomasini taqdim etishga haqli. Ushbu shartnoma shartlarini qabul qilib, Hamkor Ma'muriyat oldida qarzdorlik yuzaga kelgan taqdirda, uning hisobidan qarz summasini to'g'ridan-to'g'ri yechib olishni rad etishga yoki e'tiroz bildirishga haqli emasligini qabul qiladi, rozi qiladi va e'tirof etadi.</p>

        <p><strong>7. SHAXSIY MA'LUMOTLARNING MAXFIYLIK SIYOSOSATI</strong><br />
          7.1. Ma'lumotlar maxfiyligi siyosati Platformada ma'lumotlarning maxfiyligi munosabatlarini boshqaradi.<br />
          7.2. Ma'lumotlarning maxfiyligi qoidalari ushbu Shartnomaning bir qismi bo'lgan va 3.2-bandda ko'rsatilgan alohida hujjat bilan tartibga solinadi.</p>

        <p>7.3. Qoidalar Sayt ro'yxatdan o'tish va foydalanish paytida Foydalanuvchi haqida olishi mumkin bo'lgan barcha ma'lumotlarga nisbatan qo'llaniladi. Hamkor tomonidan saytni ro'yxatdan o'tkazish va undan foydalanish Ma'lumotlarning maxfiyligi siyosati va unda Hamkorning shaxsiy ma'lumotlarini qayta ishlash bilan bog'liq shartlar bilan so'zsiz kelishuvni anglatadi. Agar Hamkor ushbu shartlarga rozi bo'lmasa, u Platformadan foydalanmasligi kerak.<br />
          7.4. Tomonlar O‘zbekiston Respublikasining amaldagi qonunchiligiga muvofiq, Tomonlar tomonidan tijorat siri sifatida tasniflangan va Bitimni amalga oshirish jarayonida tomonlarga ma’lum bo‘lgan ma’lumotlarning, shu jumladan, lekin cheklanmagan holda, maxfiyligini saqlash majburiyatini oladilar. kimga:<br />
          - to'lovchilar, to'lovlar hajmlari to'g'risidagi ma'lumotlar;<br />
          - boshqaruv tizimining texnik hujjatlari;<br />
          - Ma'muriyatning haqi miqdori va Shartnomaning moliyaviy shartlari to'g'risidagi ma'lumotlar.<br />
          Shu bilan birga, Tomonlar mazkurshartnomasini tuzish faktini maxfiy axborot deb hisoblamaydilar.<br />
          7.5. Maxfiy ma'lumotlarni uchinchi shaxslarga berish, ushbu ma'lumotlarni e'lon qilish yoki boshqa tarzda oshkor qilish Shartnoma amal qilish muddati davomida va Shartnoma bekor qilinganidan keyin 3 (uch) yil ichida faqat Shartnomaga muvofiq yoki tomonlar o'zaro yozma roziligi bilan amalga oshirilishi mumkin. (O‘zbekiston Respublikasi qonun hujjatlariga muvofiq vakolatli davlat organlarining talablariga muvofiq maxfiy axborotni taqdim etish hollari bundan mustasno).<br />
          7.6. Tomonlar maxfiy ma’lumotlarning oshkor etilishi uchun O‘zbekiston Respublikasi qonun hujjatlariga muvofiq javobgar bo‘ladilar.</p>

        <p><strong>8. TOMONLARNING KAFOLATLARI VA VAKOLATLARI</strong><br />
          8.1. «Road24» platformasi o'z Hamkorlariga kafolatlangan sifatli xizmatlarni maxfiy ravishda taqdim etadi.<br />
          8.2. Ma'muriyat Xizmatni oluvchi va Hamkor o'rtasida tuzilgan oldi-sotdi bitimlari, xizmatlar ko'rsatish, ishlarni bajarish va boshqa bitimlar bo'yicha taraf emas.<br />
          8.4. Har bir Tomon shu bilan boshqa Tomonga quyidagilarni ifodalaydi va kafolatlaydi:<br />
          - u O‘zbekiston Respublikasi Soliq kodeksining 14 va 15-moddalari belgilangan tartibda tashkil etilgan va ro‘yxatdan o‘tgan, O‘zbekiston Respublikasi qonunchiligiga muvofiq qonuniy faoliyat ko‘rsatayotgan yuridik shaxs bo‘lib, qonun hujjatlariga muvofiq tijorat, litsenziyalanadigan faoliyatni amalga oshirish huquqiga ega. O‘zbekiston Respublikasi qonun hujjatlari yaxshi obro‘-e’tiborga ega bo‘lgan, zarur imkoniyatlarga, inventarga, shuningdek, o‘z tarkibidagi mutaxassislarga ega bo‘lishi, Ma’muriyat va tegishli davlat organlari oldidagi o‘z majburiyatlarini bajarish imkoniyatini kafolatlaydi.<br />
          - ushbu Shartnomaga rozilik bildirgan shaxs ushbu harakat uchun barcha zarur vakolatlarga ega.<br />
          - tomonlar o'z majburiyatlari bo'yicha uchinchi shaxslar oldida javobgar emaslar.<br />
          - Hech bir tomon tugatilish jarayonida emas. Tomonlar ushbu shartnomani to'g'ri bajarishga to'sqinlik qiladigan uchinchi shaxslarning taqiqlari, garovlari, majburiyatlari yoki huquqlariga ega emaslar.</p>

        <p></p>

        <p>8.5. Tomonlar bir-biriga soliq to‘lovchi sifatida ro‘yxatdan o‘tganligini, ularning moliyaviy ahvoli barqarorligini va tomonlarning mol-mulki ushbu shartnoma bo‘yicha o‘z majburiyatlarini lozim darajada bajarish uchun yetarli ekanligini kafolatlaydi.<br />
          8.6. Tomonlar va uning direktorlari, mansabdor shaxslari yoki xodimlari, ularga egalik qiladigan yoki nazorat qiladigan yoki boshqa ruxsat etilgan shaxs yoki yuridik shaxs ushbu sohada ishlashni ta’qiqlaydigan sanksiyalar obyekti hisoblanmaydi.<br />
          8.7. Tomonlar tomonidan Shartnoma bo'yicha o'z majburiyatlarini bajarish uchun jalb qilingan har qanday jismoniy yoki yuridik shaxslar, shu jumladan ularning xodimlari, agentlari, maslahatchilari, pudratchilar/yetkazib beruvchilar va subpudratchilar/pudratchilar O’zbekiston Respublikasining "Korrupsiyaga qarshi kurashish to'g'risida"gi qonuni talablariga muvofiq” (01.03.2017 y., O‘RQ-419-son):<br />
          - bu mansabdor shaxs o‘z xizmat vazifalarini bajarishda biron-bir harakat yoki harakatsizlikni amalga oshirishi uchun pora yoki boshqa korrupsion to‘lovlarni, shu jumladan biron bir davlat mansabdor shaxsiga taklif qilmasligi, va’da qilmasligi, bermasligi, talab qilmasligi yoki olmasligi;<br />
          - taraflar, ularning bevosita yoki bilvosita aktsiyadorlari (ishtirokchilari), direktorlari, mansabdor shaxslari yoki xodimlari tomonidan qasddan yoki ehtiyotsizlik oqibatida qonun buzilishiga olib kelishi mumkin bo‘lgan xatti-harakatlarga yo‘l qo‘ymaslik;<br />
          - agar Tomonlardan birortasida O‘zbekiston Respublikasining korrupsiyaga qarshi kurashish to‘g‘risidagi qonun hujjatlari talablari buzilganligi (yoki mumkin bo‘lgan buzilishi) to‘g‘risida asosli shubhalar mavjud bo‘lsa, ular boshqa Tomon va uning vakillari bilan haqiqatni aniqlash uchun vijdonan hamkorlik qilishi shart. bunday qoidabuzarlikdan.</p>

        <p></p>

        <p><strong>9. TOMONLARNING MA'LUMOTI.</strong><br />
          9.1. Tomonlar ushbu Shartnoma shartlarini bajarmaganlik yoki lozim darajada bajarmaganlik uchun O‘zbekiston Respublikasining amaldagi qonunchiligiga muvofiq javobgar bo‘ladilar.<br />
          9.2. Hamkor ushbu Shartnoma bo'yicha majburiyatlarni bajarmaslik yoki lozim darajada bajarmaslik natijasida yuzaga keladigan har qanday zarar yoki boshqa salbiy oqibatlar uchun javobgardir.<br />
          9.3. Hamkor o'z shaxsiy ma'lumotlarini xavfsiz bo'lmagan havolalardan foydalanadigan qurilmaga kiritishda yoki zararli dasturlar va ilovalar xavfi ostida bo'lganda yakka tartibda javobgardir.<br />
          9.4. Sayt Shartnoma shartlariga rioya qilmaslik uchun javobgar emas, agar bunday nomuvofiqlik Hamkor tomonidan Shartnoma shartlariga rioya qilmaslik natijasi bo'lsa.<br />
          9.5. Sayt faollashtirish vaqtida hamkor ushbu Shartnoma shartlari, shuningdek ularning takliflari bilan tanishmaganligi va (yoki) o'z vaqtida tanishmaganligi tegishli xizmatlar taqdim etilmaganligi sababli Foydalanuvchiga etkazilgan har qanday zarar uchun javobgar emas. <br />
          9.6. Agar bunday nomuvofiqlik Saytga bog'liq bo'lmagan sabablarga ko'ra yuzaga kelgan bo'lsa, Sayt Shartnoma shartlariga rioya qilmaslik uchun javobgar emas. Platformani nazorat qilish doirasi saytning o'z faoliyati bilan chegaralanadi va uchinchi shaxslarning xatolari yoki davlat organlari yoki sud organlari tomonidan qo'yilgan taqiqlar va cheklovlar tufayli ishdagi uzilishlar natijasida yuzaga kelgan nosozliklar uchun javobgarlikni o'z ichiga olmaydi.<br />
          9.7. Tomonlar ushbu Shartnoma bo'yicha majburiyatlarni bajarmaganlik yoki lozim darajada bajarmaganlik uchun javobgarlikdan ozod qilinadilar, agar bunday bajarmaslik favqulodda vaziyatlar natijasida yuzaga kelgan fors-major holatlari natijasida yuzaga kelgan bo'lsa, Tomon oldindan ko'ra olmagan yoki tuzilganidan keyin oldini olish uchun oqilona choralar ko'rgan bo'lsa. <br />
          9.8. Operator Shartnoma bo'yicha o'z majburiyatlarini to'liq yoki qisman bajarmaganlik uchun javobgarlikdan to'liq ozod qilinadi, agar bajarilmasligi yoki lozim darajada bajarilmasligi texnogen xususiyatdagi fors-major holatlari, xususan: avariya (ishlatmaslik) natijasida yuzaga kelgan bo'lsa. Saytning ishlashi uchun foydalaniladigan elektr yoki kompyuter tarmoqlari yoki boshqa elektr aloqa tizimlari, shuningdek, uchinchi shaxslarning Sayt faoliyatini buzishga qaratilgan qasddan noqonuniy harakatlari natijasida.<br />
          9.9. Yo'qolgan, o'g'irlangan yoki boshqa hollarda Hamkor Saytda ulangan mobil (abonent) telefon raqamiga kirish imkoniga ega bo'lmasa, Hamkor barcha xavf va uchinchi shaxslarning Internetdan foydalanish bilan bog'liq har qanday harakatlari uchun javobgarlikni o'z zimmasiga oladi.<br />
          9.10. Operator Hamkor oldida texnik platformalar va transport tarmoqlari yoki aloqa tarmoqlarining ishlashidagi kechikishlar va uzilishlar uchun javobgar emas, ularning paydo bo'lishi Operatorning aybi bo'lmaydi.<br />
          9.11. Operator Hamkor tomonidan Saytga kirish va/yoki boshqa shaxsiy ma'lumotlarga kirish uchun o'z ma'lumotlarini oshkor qilganligi sababli Hamkor tomonidan etkazilgan har qanday zarar uchun javobgar emas.<br />
          9.12. Operator Foydalanuvchiga hamkorlar tomonidan taqdim etilayotgan Xizmatlarning sifati, shuningdek, Foydalanuvchi tomonidan bunday hamkorlardan sotib olingan xizmatlarni taqdim etish jarayonida va Hamkorlar oldidagi yoki foydalanuvchilar oldidagi majburiyatlari uchun javobgar emas.<br />
          9.13. Hamkor o'zi tomonidan Saytda amalga oshirilgan barcha operatsiyalar uchun javobgardir.<br />
          9.14. Operator boshqa tashkilotlar, hamkorlar tomonidan taqdim etilgan aloqa liniyalarining sifati va unga tegishli bo'lmagan boshqa Hamkorlarga tegishli bo'lgan boshqa, shu jumladan tranzit, ma'lumotlarni uzatish tarmoqlari tugunlarining jihozlarining ishlashi uchun javobgar emas.<br />
          9.15. Platforma, agar Toʻlovlarni amalga oshirish muddatlarining buzilishi Hamkor va xizmatlarni oluvchi oʻrtasidagi Shartnoma shartlari bajarilmaganligi yoki lozim darajada bajarilmaganligi sababli yuzaga kelgan boʻlsa, Xizmat oluvchilar tomonidan Toʻlovlarni amalga oshirish muddatlarining buzilishi uchun javobgar emas.<br />
          9.16. Hamkor Sayt ulangan Hamkor nomidan sodir etilgan uchinchi shaxslarning har qanday harakatlari, shuningdek uning qurilmasida o'rnatilgan ilovalar va dasturlardan foydalanish yoki Saytga kirish uchun uning Hisob ma'lumotlaridan foydalanish uchun xavf va javobgarlikni o'z zimmasiga oladi.</p>

        <p></p>

        <p></p>

        <p></p>

        <p></p>

        <p><strong>10. SHARTNOMANI TUGATISH.</strong><br />
          10.1. Shartnoma quyidagi hollarda bekor qilinishi mumkin:<br />
          - Tomonlarning kelishuviga binoan;<br />
          - bir tomonlama agar Shartnomada nazarda tutilgan kelishmovchiliklarni hal qilish tartibini hisobga olgan holda, boshqa Tomon tomonidan Bitimning bir yoki bir nechta shartlari takroran buzgan taqdirda;<br />
          - Tomonlardan birortasining tashabbusi bilan, Shartnomani bekor qilishning kutilayotgan sanasidan 30 (o‘ttiz) kalendar kunidan kechiktirmay boshqa Tomon xabardor qilingan holda. Xabarnoma qog'ozda yozma ravishda amalga oshirilishi va Shartnomani bekor qilish sababini ko'rsatishi kerak.<br />
          10.2. Shartnomaning bekor qilinishi Tomonlar uni bekor qilingan sanadan oldin tuzilgan Shartnoma bo'yicha barcha o'zaro hisob-kitoblarni amalga oshirish majburiyatidan ozod qilmaydi. Shartnoma bekor qilingan taqdirda, Tomonlar bekor qilingan sanada barcha o'zaro hisob-kitoblarni amalga oshirish majburiyatini oladilar.<br />
          10.3. Ma'muriyat, agar Hamkor ushbu Shartnoma shartlarini buzsa, Platformadagi Hamkor hisobini o'chirish va Shartnomani bekor qilish huquqini o'zida saqlab qoladi.<br />
          10.4. Ushbu Shartnoma qonun hujjatlarida nazarda tutilgan boshqa asoslarda ham bekor qilinishi mumkin.<br />
          10.5. Shartnoma hisob o'chirilgandan keyin bekor qilingan hisoblanadi. Hisobni o'chirishdan oldin, tomonlar Shartnomani bekor qilishdan oldin paydo bo'lgan majburiyatlarni bajarishlari kerak.</p>

        <p></p>

        <p><strong>11. NIZOLARNI HAL QILISH TARTIBI</strong><br />
          11.1. Ushbu Shartnoma bilan bog'liq yoki undan kelib chiqadigan har qanday nizo muzokaralar yo'li bilan yoki Sherikning yozma iltimosiga binoan talabnoma tartibida hal qilinadi. Murojaatni ko‘rib chiqish muddatlari va uning mazmuniga qo‘yiladigan talablar ushbu Shartnoma va O‘zbekiston Respublikasining amaldagi qonunchiligi bilan belgilanadi.<br />
          11.2. Agar nizo talabnoma tartibi bilan hal etilmasa, u O‘zbekiston Respublikasining amaldagi qonunchiligida belgilangan tartibda javobgar turgan joydagi tegishli sud tomonidan ko‘rib chiqilishi mumkin.</p>

        <p>
          <strong>12. BOSHQA SHARTLAR</strong><br />
          12.1. Platforma tegishli xizmatlarni birgalikda ko'rsatuvchi tashkilotlarning ma'lumotlar bazalari bilan birlashtirilgan va bu tashkilotlar Hamkor ma'lumotlarini oladi. Hamkorlar haqidagi ma'lumotlar ham ushbu tashkilotlar tomonidan Platformaga taqdim etiladi.</p>

        <p>12.2. Uchinchi tomon saytlari va kontenti:<br />
          12.2.1. Saytda Internet tarmog‘idagi boshqa saytlarda (uchinchi shaxslarga tegishli saytlar) O‘zbekiston Respublikasi qonunchiligi bilan qo‘riqlanadigan intellektual faoliyat natijasi bo‘lgan maqolalar, fotosuratlar, illyustratsiyalar, grafikalar, ma’lumotlar, ilovalar, dasturlar va boshqa kontentga havolalar bo‘lishi mumkin. ).<br />
          12.2.2. Uchinchi shaxslarga tegishli deb ko'rsatilgan kontent Sayt ma'muriyati tomonidan hech qanday talablarga (ishonchlilik, to'liqlik, xolislik) muvofiqligi tekshirilmaydi. Sayt ma'muriyati Platformada joylashtirilgan uchinchi shaxslarga oid har qanday ma'lumotlarga ruxsat berilgan yoki Hamkor tomonidan Uchinchi Shaxs kontentidan olingan ma'lumotlar, shuningdek uchinchi shaxslar tomonidan bildirilgan yoki ularning Kontentida ifodalangan ifodalar, fikrlar yoki ma'qullashlar uchun javobgar emas.<br />
          12.2.3. Platformada joylashtirilgan har qanday Platforma, mahsulot, xizmat haqidagi tijorat yoki notijorat xarakterdagi har qanday ma'lumotlar ularning Sayt ma'muriyati tomonidan tavsiya etilgan mazmunini tashkil etmaydi.<br />
          12.3. Ushbu Taklifni qabul qilib, Hamkor Shartnomaning barcha shartlariga so'zsiz roziligini tasdiqlaydi va ularga rioya qilish majburiyatini oladi.</p>

        <p>12.4. Saytning tegishli saytlari va bo'limlarida ulardan foydalanish qoidalari, ko'rsatmalari, ma'lumotlari va tavsiyalari ushbu Shartnomaning ajralmas qismi hisoblanadi. Ushbu Saytlar va Saytning bo'limlari va funktsiyalaridan foydalangan holda, Hamkor ulardagi qoidalar, ko'rsatmalar, ma'lumotlar va tavsiyalarga rozi bo'ladi.</p>

        <p>12.5. Ushbu Shartnomaning amal qilish muddati cheklanmagan. Agar tomonlardan hech biri shartnomaviy munosabatlarni bekor qilish istagini bildirmasa, shartnoma keyingi yilga uzaytirilgan hisoblanadi. Bunday holda, Ma'muriyat kelgusi yilga to'lov uchun hisob-fakturani taqdim etadi.</p>

        <p>12.6. Ushbu Ommaviy oferta rasmiy hujjat bo‘lib, unga ilova qilingan hujjatlar Ommaviy ofertaning ajralmas qismi hisoblanadi.<br />
          12.7. Tomonlar shaxsiy ma'lumotlari o'zgargan taqdirda bir-birlarini darhol xabardor qilishlari va Platformaga o'zgartirishlar kiritishlari shart.<br />
          12.8. Shartnomada ko'rsatilmagan boshqa munosabatlar ushbu Bitim maqsadlariga muvofiq O'zbekiston Respublikasining amaldagi qonunchiligi bilan tartibga solinadi.<br />
          12.9. Tomonlar o‘zlarining yuridik, haqiqiy, pochta manzili va bank rekvizitlaridagi o‘zgarishlar to‘g‘risida bunday o‘zgartirishlar kiritilgan kundan boshlab 3 (uch) ish kunidan kechiktirmay bir-birlarini xabardor qilishlari shart. Ushbu o'zgartirishlar Tomonning vakolatli shaxsi tomonidan imzolangan va Tomon muhri bilan tasdiqlangan tegishli bildirishnomani olgandan keyin Tomonlar uchun majburiy bo'ladi. Xabarnoma olingunga qadar ilgari ko'rsatilgan rekvizitlar bo'yicha bajarilgan majburiyatlar tegishli tarzda bajarilgan deb hisoblanadi.</p>

        <p></p>

        <p>
          12.10. Tomonlar boshqa Tomonning oldindan roziligisiz reklama va axborot materiallarida foydalanishga, Internet tarmog‘ida Tomonga tegishli bo‘lgan veb-saytlarda boshqa Tomonning firma nomini, logotipini joylashtirish huquqiga ega emaslar. shuningdek, u tomonidan qo'llaniladigan individuallashtirish vositalari (masalan, firma nomi, tovar belgisi (xizmat ko'rsatish belgisi), tijorat belgisi va boshqalar), Tomonlar shartnomaviy-huquqiy munosabatlarda ekanligi to'g'risidagi ma'lumotlar, bir-birlari haqida hamma uchun ochiq bo'lgan har qanday ma'lumotlar.<br />
          <strong>13. SAYTNING MA'MURIY MA'LUMOTLARI</strong><br />
          MChJ "KASH APP"<br />
          Hisob: 2020 8000 5006 0266 7001<br />
          INN: 303 858 131<br />
          Bank kodi: 01183<br />
          Bank: OAJ "ANORBANK"<br />
          E-mail: salom@kash.uz<br />
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicyYur;
